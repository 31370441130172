/* v1home.css */
@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .animate-fade-in {
    animation: fade-in 1s ease-out forwards;
  }
  
  .animate-slide-up {
    animation: slide-up 1s ease-out forwards;
  }
  
  .delay-200 {
    animation-delay: 0.2s;
  }
  
  .delay-500 {
    animation-delay: 0.5s;
  }
  
  .transform {
    transform-origin: center;
  }
  
  .hover\:scale-105:hover {
    transform: scale(1.05);
  }

  .hero-section {
    position: relative;
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  
  .hero-content {
    position: relative;
    z-index: 1;
    text-align: center;
  }