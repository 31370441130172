#input-table th {
    background-color: rgb(00,00,00);
    font-weight: 500;
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
} 

#input-table td {
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    padding-left: 5px;
    padding-right: 5px;
}

#data td {
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    padding-left: 5px;
    padding-right: 5px;
    border: 0.5px solid rgb(00,00,00);
}

#input-table th:first-child {border-top-left-radius: 7px}
#input-table th:nth-child(1) {width: 4%; text-align: center}
#input-table th:nth-child(2) {width: 7.5% ; text-align: center}
#input-table th:nth-child(3) {width: 38.5% ; text-align: center}
#input-table th:nth-child(4) {width: 7% ; text-align: center}
#input-table th:nth-child(5) {width: 8% ; text-align: center}
#input-table th:nth-child(6) {width: 5% ; text-align: center}
#input-table th:nth-child(7) {width: 5% ; text-align: center}
#input-table th:nth-child(8) {width: 3% ; text-align: center}
#input-table th:nth-child(9) {width: 4% ; text-align: center}
#input-table th:nth-child(10) {width: 3% ; text-align: center}
#input-table th:nth-child(11) {width: 8.5% ; text-align: center}
#input-table th:nth-child(12) {width: 8.5% ; text-align: center}
#input-table th:last-child {border-top-right-radius: 7px}

#input-table td:first-child {border-top-left-radius: 7px}
#input-table td:nth-child(1) {width: 4% ; text-align: center}
#input-table td:nth-child(2) {width: 7.5% ; text-align: center}
#input-table td:nth-child(3) {width: 38.5%; text-align: left; max-width: 500px}
#input-table td:nth-child(4) {width: 7% ; text-align: center}
#input-table td:nth-child(5) {width: 8% ; text-align: center; max-width: 50px}
#input-table td:nth-child(6) {width: 5% ; text-align: center}
#input-table td:nth-child(7) {width: 5% ; text-align: center}
#input-table td:nth-child(8) {width: 3% ; text-align: center}
#input-table td:nth-child(9) {width: 4% ; text-align: center}
#input-table td:nth-child(10) {width: 3% ; text-align: center; color: rgb(165, 165, 165)}
#input-table td:nth-child(11) {width: 8.5% ; text-align: center; color: rgb(165, 165, 165)}
#input-table td:nth-child(12) {width: 8.5% ; text-align: center; color: rgb(165, 165, 165)}
#input-table td:last-child {border-top-right-radius: 7px}

/* #data td:first-child {border-top-left-radius: 7px} */
#data td:nth-child(1) {width: 4% ; text-align: center}
#data td:nth-child(2) {width: 7.5% ; text-align: center}
#data td:nth-child(3) {width: 38.5%; text-align: left; max-width: 500px}
#data td:nth-child(4) {width: 7% ; text-align: center}
#data td:nth-child(5) {width: 8% ; text-align: center; max-width: 50px}
#data td:nth-child(6) {width: 5% ; text-align: center}
#data td:nth-child(7) {width: 5% ; text-align: center}
#data td:nth-child(8) {width: 3% ; text-align: center}
#data td:nth-child(9) {width: 4% ; text-align: center}
#data td:nth-child(10) {width: 3% ; text-align: center; color: rgb(165, 165, 165)}
#data td:nth-child(11) {width: 8.5% ; text-align: center; color: rgb(165, 165, 165)}
#data td:nth-child(12) {width: 8.5% ; text-align: center; color: rgb(165, 165, 165)}
/* #data td:last-child {border-top-right-radius: 7px} */

#data td:nth-child(9) {width: 4% ; text-align: center; background-color: rgb(30,30,30)}

/* #input-header {
    position: sticky;
    top: 0%;
} */

.data-input {
    width: 100%;
    height: 20px;
    background-color: rgb(30,30,30);
    border: 0.5px solid rgb(71,71,71);
    border-style: none;
    font-size: 11px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
}
  
.data-input:focus {
    outline: 0px;
    height: 20px;
    width: 100%;
    outline: 1px solid rgb(45, 106, 110);
    font-size: 11px;
}

/* .data-input::placeholder {
    color: gray;
    opacity: 1;
} */

#submit-btn {
    height: 20px;
    background-color: rgb(71,71,71);
    border: 0.5px solid rgb(71,71,71);
    border-style: none;
    color: white;
    font-size: 11px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
}

@media (max-width: 500px) {
    #input-table th:nth-child(1) {width: 25% ; text-align: center}
    #input-table th:nth-child(2) {width: 33% ; text-align: center}
    #input-table th:nth-child(3) {width: 14% ; text-align: center}
    #input-table th:nth-child(4) {width: 14% ; text-align: center}
    #input-table th:nth-child(5) {width: 14% ; text-align: center}

    #input-table td:nth-child(1) {width: 25% ; text-align: center}
    #input-table td:nth-child(2) {width: 33% ; text-align: center}
    #input-table td:nth-child(3) {width: 14% ; text-align: center}
    #input-table td:nth-child(4) {width: 14% ; text-align: center}
    #input-table td:nth-child(5) {width: 14% ; text-align: center}

    #data td:nth-child(1) {width: 25% ; text-align: center}
    #data td:nth-child(2) {width: 33% ; text-align: center}
    #data td:nth-child(3) {width: 14% ; text-align: center}
    #data td:nth-child(4) {width: 14% ; text-align: center}
    #data td:nth-child(5) {width: 14% ; text-align: center}
}



/*
th {
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis; 
    padding-right: 10px;
    border: black 1px solid;
}


tr {
    height: 25px; 
}

#th1 {width: 3% ; text-align: center}
#th2 {width: 6% !important; text-align: center}
#th3 {width: 49% !important; text-align: center}
#th4 {width: 6% !important; text-align: center}
#th5 {width: 9% !important; text-align: center}
#th6 {width: 4% !important; text-align: center}
#th7 {width: 4% !important; text-align: center}
#th8 {width: 4% !important; text-align: center}
#th9 {width: 7.5% !important; text-align: center}
#th10 {width: 7.5% !important; text-align: center}
#th11 {width: 7.5% !important; text-align: center}




#data-out {
    margin-top: -1px;
}
#data-in {
    margin-top: 0px;
    background-color: rgb(71, 71, 71);
}



  
div:focus {
    outline: 0px;   
}


#timeStrong {
    font-weight: 750;
}

*/