#input-table-pending th {
    background-color: rgb(00,00,00);
    font-weight: 500;
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
}

#input-table-pending td {
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    padding-left: 5px;
    padding-right: 5px;
}

#output-table-pending {
    overflow: hidden;
}

#output-table-pending td {
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    padding-left: 5px;
    padding-right: 5px;
    border: 0.5px solid rgb(00,00,00);
}

#input-table-pending th:first-child {border-top-left-radius: 7px}
#input-table-pending th:nth-child(1) {width: 3%; text-align: center}
#input-table-pending th:nth-child(2) {width: 7% ; text-align: center}
#input-table-pending th:nth-child(3) {width: 6% ; text-align: center}
#input-table-pending th:nth-child(4) {width: 60% ; text-align: center; max-width: 500px}
#input-table-pending th:nth-child(5) {width: 7% ; text-align: center}
#input-table-pending th:nth-child(6) {width: 7% ; text-align: center}
#input-table-pending th:nth-child(7) {width: 5% ; text-align: center}
#input-table-pending th:nth-child(8) {width: 5% ; text-align: center}
#input-table-pending th:last-child {border-top-right-radius: 7px}

#input-table-pending td:first-child {border-top-left-radius: 7px}
#input-table-pending td:nth-child(1) {width: 3%; text-align: center}
#input-table-pending td:nth-child(2) {width: 7% ; text-align: center}
#input-table-pending td:nth-child(3) {width: 6% ; text-align: center}
#input-table-pending td:nth-child(4) {width: 60%; text-align: center; max-width: 500px}
#input-table-pending td:nth-child(5) {width: 7% ; text-align: center}
#input-table-pending td:nth-child(6) {width: 7% ; text-align: center}
#input-table-pending td:nth-child(7) {width: 5% ; text-align: center}
#input-table-pending td:nth-child(8) {width: 5% ; text-align: center}
#input-table-pending td:last-child {border-top-right-radius: 7px}

#output-table-pending td:nth-child(1) {width: 3%; text-align: center}
#output-table-pending td:nth-child(2) {width: 7% ; text-align: center}
#output-table-pending td:nth-child(3) {width: 6% ; text-align: center}
#output-table-pending td:nth-child(4) {width: 60% ; text-align: left; max-width: 500px}
#output-table-pending td:nth-child(5) {width: 7% ; text-align: center}
#output-table-pending td:nth-child(6) {width: 7% ; text-align: center}
#output-table-pending td:nth-child(7) {width: 5% ; text-align: center}
#output-table-pending td:nth-child(8) {width: 5% ; text-align: center}


@media (max-width: 500px) {
    #input-table-pending th:nth-child(1) {width: 25%; text-align: center}
    #input-table-pending th:nth-child(2) {width: 10% ; text-align: center}
    #input-table-pending th:nth-child(3) {width: 45% ; text-align: center}
    #input-table-pending th:nth-child(4) {width: 20% ; text-align: center}

    #input-table-pending td:nth-child(1) {width: 25%; text-align: center}
    #input-table-pending td:nth-child(2) {width: 10% ; text-align: center}
    #input-table-pending td:nth-child(3) {width: 45% ; text-align: center}
    #input-table-pending td:nth-child(4) {width: 20%; text-align: center}

    #output-table-pending td:nth-child(1) {width: 25%; text-align: center}
    #output-table-pending td:nth-child(2) {width: 10% ; text-align: center}
    #output-table-pending td:nth-child(3) {width: 45% ; text-align: left; max-width: 50px}
    #output-table-pending td:nth-child(4) {width: 20% ; text-align: center}
}